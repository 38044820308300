.about-us {
    /* background-color: #f9f9f9; */
    /* padding: 40px 0; */
    text-align: center;
  }
  
  .section {
    background-color: #f1f1f1;;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    margin: 20px;
    color:#212121;
    border-radius: 10px;
    transition: all 0.3s ease-in-out; /* Smooth transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow */
  }
  
  .section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .section p {
    font-size: 16px;
  }

  .about-us-bg{
     background: linear-gradient(135deg, #4CAF50, #65BB6B);
     background-size: cover;
     padding-top: 4rem;
     padding-bottom: 4rem;
  }
  
  @media screen and (min-width:768px)  {
    .vision-col{
        display: block;
        margin-left:auto;
        margin-right:auto;
    }
  }

  .about-us-title{
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    color: #000000;
    margin-bottom: 2rem;
  }

  .section:hover {
    transform: scale(1.05); /* Slightly scale up */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
    background-color: #f1f1f1; /* Change background color on hover */
}

@media screen and (max-width:767.7px) {
  .section p {
    text-align: justify;
    hyphens: auto;
  }
}