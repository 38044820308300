/* Footer.css */
.footer {
    background-color: #333; /* Dark grey background */
    color: #fff; /* White text */
    padding: 20px 0;
  }
  
  .logo-follow {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 180px; 
    margin-right: 20px; 
    border-radius: 10px;
  }
  .social-icon {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    margin-right: 10px; /* Add spacing between icons */
  }
  .icon {
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    margin-right: 10px; /* Add spacing between the icon and text */
    vertical-align: middle; /* Vertically align the icon with the text */
  }
  .first-icon{
    margin-left:40px;
  }
  .footer-first-row{
    margin-bottom: 2rem;
  }
  .footer-second-row{
    margin-bottom: 2rem;
  }
  .link {
    color: white; /* Set link text color to white */
    text-decoration: none; /* Remove underline */
  }
  
  .follow-us a {
    color: #fff; /* White social media icons */
    text-decoration: none;
    margin: 0 10px; /* Add spacing between icons */
    width:50px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-links a {
    list-style: none;
    padding: 0;
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-links li {
    margin: 5px 0;
    list-style: none;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .copyright {
    text-align: center;
  }
  
  @media screen and (max-width:767.7px){
    .logo-follow{
   justify-content: center;
    }
    .follow-us{
      margin-top: 2rem;
    }
    .footer-links, .contact-info{
     text-align: center;
    }
  }
  .social-icon:hover {
    transform: scale(1.2); /* Increase size on hover */
    filter: brightness(1.2); /* Optional: brighten the icon on hover */
}