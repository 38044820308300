/* src/components/Header.css */

.header {
  display: flex;
}

.carousel {
  width: 50%; /* Adjust as needed */
  border-radius: 20px;
  border: 2px solid #ffffff;
}

.image-info {
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0; /* Background color for the info section */
}

.carousel .slide {
  min-width: 300px;
  max-width: 100;
  height: auto;
  border-radius: 10px;
}

.header-bg {
  background-image: url("../Images/impact_bg.webp");
  padding-bottom: 7rem;
  color: #fff;
  background-size: cover;
  padding-top: 12rem;
}

/* Add this CSS to your Header.css or your preferred stylesheet */
.image-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding for spacing */

  /* You can style the caption and additional information as needed */
  background-color: transparent; /* Semi-transparent white background */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  text-align: center;
  max-width: 100%; /* Adjust as needed */
  margin-left: 20px; /* Add spacing between the carousel and caption */
  height: 100%;
}

.image-info h2 {
  font-size: 45px;
  margin: 10px 0;
  color: #ffffff; /* Adjust the color as needed */
}

.image-info p {
  font-size: 14px;
  color: #666; /* Adjust the color as needed */
}
.carousel .control-dots {
  display: none;
}

@media screen and (max-width: 767.7px) {
  .header-bg {
    padding-bottom: 3rem;
    color: #fff;
    background-size: cover;
    padding-top: 9.5rem;
  }
  .image-info h2 {
    font-size: 23px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .header {
    flex-direction: column;
  }
  .carousel {
    width: 100%;
  }
  .image-info {
    padding: 10px;
  }
  .carousel .control-prev.control-arrow,
  .carousel .control-next.control-arrow {
    display: none;
  }
  /* Styling for the h2 element */
  .image-info h2 {
    height: 58px; /* Set fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    white-space: normal; /* Allow text to wrap */
    position: relative; /* Needed for the scrollbar styling */
  }

  /* Custom scrollbar styling */
  .image-info h2::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar by default */
  }

  .image-info h2:hover::-webkit-scrollbar {
    width: 6px; /* Show scrollbar on hover */
  }

  .image-info h2::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 4px; /* Round scrollbar edges */
  }

  .image-info h2::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .image-info h2 {
    font-size: 30px;
  }
}
