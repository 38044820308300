/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 20px 0;
    background-color: transparent;
    transition: background-color 0.3s;
    z-index: 1000;
  }
  
  .navbar.scrolled {
    background-color: #333;
    color: #ffffff;
  }

  /* .navbar.scrolled .navbar-links a{
    color: #ffffff;
  } */
  
  .navbar-logo {
    font-size: 1.5rem;
    margin-left: 20px;
    color: white;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .navbar-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #212121 ;
    font-weight: 600;
  }

  .mobile-menu-icon {
    display: none; /* Hide on larger screens */
    cursor: pointer;
  }
  
  .brand-logo{
    width:200px !important;
    height: auto;
    border-radius: 10px;
  }
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: 0.4s;
  }
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
  }
  
  /* ... (Previous styles) */
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-menu {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .navbar-menu li {
    margin: 0 15px;
  }
  

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
  }
  
  /* ... (Previous styles) */
  
  .navbar-links {
   
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .navbar-links.open {
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
  }
  
  .navbar-links li {
    margin: 15px 0;
  }
  
  
  /* Mobile styles */
  @media (max-width: 991.2px) {
    .navbar-links {
      display: none; /* Hide on mobile by default */
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      background-color: blue;
      width: 100%;
      text-align: center;
    }
  
    .navbar-links.open {
      display: flex;
    }
  
    .mobile-menu-icon {
      display: block; /* Show on mobile */
    }
  
    .bar.open:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .bar.open:nth-child(2) {
      opacity: 0;
    }
  
    .bar.open:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

  @media screen and (min-width:992px) {
    .navbar-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        padding: 0 20px;
      }
      
    
  }
  
 
  