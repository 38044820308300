.product-section {
    background-color: #f9f9f9;
    padding: 20px 0;
  }

  .section-title{
    text-align: center;
    margin-top: 2rem; 
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
  }
  
  .product-container {
    display: flex;          /* Use Flexbox for horizontal layout */
    overflow-x: auto;       /* Enable horizontal scrolling */
    white-space: nowrap;    /* Prevent wrapping to next line */
    padding: 20px 0;        /* Add vertical padding */
    gap: 10px;              /* Optional: add spacing between boxes */
  }
  
  .product-container::-webkit-scrollbar {
    display: none;          /* Hide scrollbar for Chrome, Safari, and Edge */
  }

  .product-box {
    width: 300px;           /* Fixed width for each product box */
    border: 1px solid #ddd; /* Border for each product box */
    border-radius: 8px;     /* Optional: rounded corners */
    padding: 10px;          /* Padding inside the box */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
    text-align: center;     /* Center text inside the box */
    background-color: #fff; /* Background color for each box */
  }
  
  .product-image {
    max-width: 100%;        /* Ensure image fits within the box */
    height: auto;           /* Maintain aspect ratio */
    border-bottom: 1px solid #ddd; /* Border below image */
    margin-bottom: 10px;    /* Space between image and caption */
  }
  
  .product-caption {
    font-size: 16px;  
    color: #333;            /* Text color */
   width: 280px !important;
   overflow-x: auto;       /* Enable horizontal scrolling */
   white-space: wrap; 
  }

  .product-box:hover {
    transform: scale(0.95);    
    background-color: #f3f3f3; 
  }